import React, { useRef } from 'react'
import { styled } from '@mui/material/styles'
import { Card, CardContent, Container, Grid, Typography } from '@mui/material'
import Head from 'next/head'
import UserLayout from '../layouts/UserLayout'
import Calculator from '../layouts/components/Calculator'
import fetchWithHeaders from '../configs/fetchWithHeaders'
import { fetchNavigationMenu } from '../configs/serverSideNavItems'
import CategoryCard from '../layouts/components/cards/CategoryCard'

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingTop: `${theme.spacing(20)} !important`,
  paddingBottom: `${theme.spacing(20)} !important`,
  [theme.breakpoints.up('sm')]: {
    paddingLeft: `${theme.spacing(20)} !important`,
    paddingRight: `${theme.spacing(20)} !important`
  }
}))

const StyledSection = styled('section')(({ theme }) => ({
  marginTop: theme.spacing(6),
  border: 'none',
  boxShadow: 'none'
}))

const Home = ({ categories, mainPageData }) => {
  const calculatorRef = useRef()

  const jsonLd = {
    '@context': 'http://schema.org',
    '@graph': [
      {
        '@type': 'WebSite',
        '@id': 'https://calculate-input.com#website',
        url: 'https://calculate-input.com',
        name: 'Calculate Input',
        description: mainPageData.description,
        publisher: {
          '@type': 'Organization',
          name: 'calculate-input.com',
          logo: {
            '@type': 'ImageObject',
            url: 'https://calculate-input.com/logo.png'
          }
        }
      },
      {
        '@type': 'WebPage',
        '@id': 'https://calculate-input.com#webpage',
        url: 'https://calculate-input.com',
        name: mainPageData.title,
        description: mainPageData.description,
        isPartOf: { '@id': 'https://calculate-input.com#website' },
        mainEntity: {
          '@type': 'ItemList',
          itemListElement: categories.map((category, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            item: {
              '@type': 'Article',
              name: category.title,
              url: `https://calculate-input.com/${category.path}`
            }
          }))
        }
      }
    ]
  }

  return (
    <>
      <Head>
        <title>{mainPageData.title}</title>
        <meta name='description' content={mainPageData.meta_description} />
      </Head>
      <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />
      <Container
        component='header'
        maxWidth='md'
        sx={{
          textAlign: 'center',
          padding: theme => theme.spacing(8, 0)
        }}
      >
        <Typography variant='h6' component='h1' gutterBottom sx={{ fontWeight: 600 }}>
          {mainPageData.slogan}
        </Typography>
        <Typography component='p' variant='h6' sx={{ color: 'text.secondary' }}>
          {mainPageData.meta_description}
        </Typography>
      </Container>

      <Grid item xs={12} md={8} mb={4}>
        <section>
          <Calculator calculatorRef={calculatorRef} />
        </section>
      </Grid>
      <Container></Container>

      <StyledSection>
        <Card>
          <StyledCardContent sx={{ pt: 0 }}>
            <Typography variant='h5' sx={{ mb: 4, mt: 0, fontWeight: 600, textAlign: 'center' }}>
              Popular Calculators
            </Typography>
            <Grid container spacing={4}>
              {categories.map(category => (
                <Grid item xs={12} sm={6} md={4} key={category.id || category.path}>
                  <CategoryCard category={category} />
                </Grid>
              ))}
            </Grid>
          </StyledCardContent>
        </Card>
      </StyledSection>
    </>
  )
}

export async function getStaticProps({ locale }) {
  const menuItems = await fetchNavigationMenu(locale)
  let categories = []

  let mainPageData = {}
  try {
    const resMainPage = await fetchWithHeaders(`/main-page?lang=${locale}`)
    if (!resMainPage.ok) {
      console.error('Failed to fetch main page data:', resMainPage.statusText)
    } else {
      mainPageData = await resMainPage.json()
    }
  } catch (error) {
    console.error('Error fetching main page data:', error)
  }

  try {
    const res = await fetchWithHeaders(`/page-category/all-categories?lang=${locale}`)
    if (!res.ok) {
      console.error('Failed to fetch categories:', res.statusText)

      return { notFound: true }
    }

    categories = await res.json()
    for (let category of categories) {
      const resFormulas = await fetchWithHeaders(`/page-category/${category.path}/?lang=${locale}`)
      if (!resFormulas.ok) {
        console.error(`Failed to fetch formulas for category ${category.path}:`, resFormulas.statusText)

        return { notFound: true }
      }

      const response = await resFormulas.json()
      category.formulas = response.children
    }
  } catch (error) {
    console.error(error)

    return { notFound: true }
  }

  return {
    props: {
      categories,
      menuItems,
      mainPageData: mainPageData[0] || {}
    }
  }
}

Home.getLayout = page => <UserLayout menuItems={page.props.menuItems}>{page}</UserLayout>

export default Home
