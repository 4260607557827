import React, { useRef, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import Link from 'next/link'

const CustomCard = styled(Card)(({ theme }) => ({
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)'
  },
  overflow: 'hidden'
}))

const CustomCardContent = styled(CardContent)({
  transition: 'max-height 0.3s ease',
  maxHeight: '150px',
  overflowY: 'hidden',
  '&.hovered::-webkit-scrollbar': {
    width: '8px'
  },
  '&.hovered::-webkit-scrollbar-thumb': {
    background: 'rgba(0,0,0,0.6)',
    borderRadius: '4px'
  },
  '&.hovered::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(0,0,0,0.8)'
  }
})

export default function CategoryCard({ category }) {
  const [hovered, setHovered] = useState(false)
  const contentRef = useRef(null)

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }

  return (
    <article onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <CustomCard>
        <CustomCardContent
          ref={contentRef}
          className={hovered ? 'hovered' : ''}
          style={{
            maxHeight: hovered ? '300px' : '150px',
            overflowY: hovered ? 'scroll' : 'hidden'
          }}
        >
          <Typography gutterBottom variant='h5' style={{ textDecoration: 'none', color: 'inherit' }}>
            <Link href={`/${category.path}`}>{category.title}</Link>
          </Typography>
          <List>
            {category.formulas.map(formula => (
              <ListItem key={formula.id || formula.path}>
                <Link href={`/${category.path}/${formula.path}`}>
                  <ListItemText primary={formula.title} />
                </Link>
              </ListItem>
            ))}
          </List>
        </CustomCardContent>
      </CustomCard>
    </article>
  )
}
