import React, { useState } from 'react'
import { Button, Grid, List, ListItem, Paper, TextField, Typography } from '@mui/material'
import * as math from 'mathjs'

function Calculator({ calculatorRef }) {
  const [value, setValue] = useState('')
  const [output, setOutput] = useState('')
  const [history, setHistory] = useState([])
  const [resultCalculated, setResultCalculated] = useState(false)
  const [isSqrt, setIsSqrt] = useState(false)

  const handleClick = event => {
    const symbol = event.target.name

    if (symbol === '√') {
      setIsSqrt(true)
      setValue(prevValue => prevValue + '√')
    } else {
      if (isSqrt) {
        const sqrtValue = 'sqrt(' + symbol + ')'
        setValue(prevValue => prevValue.replace('√', sqrtValue))
        setIsSqrt(false)
      } else {
        if (resultCalculated && symbol !== '=') {
          setValue(output + symbol)
          setResultCalculated(false)
        } else if (!resultCalculated && symbol !== '=') {
          setValue(prevValue => prevValue + symbol)
        }
      }
    }
  }

  const calculate = () => {
    try {
      const processedValue = value.replace('√', 'sqrt(').replace(/%/g, ')*0.01*')
      const result = math.evaluate(processedValue)
      const newOutput = value + ' = ' + result
      setOutput(String(result))
      setHistory(prevHistory => [newOutput, ...prevHistory])
      setResultCalculated(true)
    } catch (e) {
      setOutput('Error')
    }
  }

  const reset = () => {
    setValue('')
    setOutput('')
    setHistory([])
    setResultCalculated(false)
    setIsSqrt(false)
  }

  return (
    <Grid container spacing={1} justifyContent='center' ref={calculatorRef} className='calculator-form'>
      <Grid item xs={10} sm={8} md={6} lg={4}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id='outlined-read-only-input'
                label='calculate input'
                value={resultCalculated ? output : value}
                InputProps={{
                  readOnly: true
                }}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='7' onClick={handleClick}>
                7
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='8' onClick={handleClick}>
                8
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='9' onClick={handleClick}>
                9
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='/' onClick={handleClick}>
                /
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='4' onClick={handleClick}>
                4
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='5' onClick={handleClick}>
                5
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='6' onClick={handleClick}>
                6
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='*' onClick={handleClick}>
                *
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='1' onClick={handleClick}>
                1
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='2' onClick={handleClick}>
                2
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='3' onClick={handleClick}>
                3
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='-' onClick={handleClick}>
                -
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='.' onClick={handleClick}>
                .
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='0' onClick={handleClick}>
                0
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='=' onClick={calculate}>
                =
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='+' onClick={handleClick}>
                +
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='%' onClick={handleClick}>
                %
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='√' onClick={handleClick}>
                √
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name='(' onClick={handleClick}>
                (
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant='outlined' name=')' onClick={handleClick}>
                )
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' color='secondary' onClick={reset}>
                Clear
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6'>History:</Typography>
              <List>
                {history.map((item, index) => (
                  <ListItem key={index}>
                    <Typography>{item}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Calculator
